import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from "@material-ui/core";
import { parseDate, ParsingOption as ChronoParsingOption } from "chrono-node";
import { addMinutes, isAfter } from "date-fns";
import { ChangeEvent, FocusEvent, useCallback, useMemo } from "react";
import { FieldValues } from "react-hook-form";
import { IRhfControl } from ".";
import { FormValidators } from "..";
import { useDateTimeFormatUtils } from "../../../hooks/useDateTimeFormatUtils";
import { addTime, TimeFormat } from "../../../utils/dates";
import { deepmerge } from "../../../utils/objects";
import { parseTime } from "../../../utils/time";
import { durationControlParseDuration } from "./DurationControl";
import { RhfControlled, RhfControlledProps, RhfControlledRenderProps } from "./RhfControlled";

export const TimeControlValidators: FormValidators = {
  afterTime: (value: string, time: string, error: string) => {
    const timeParsed = parseDate(time);
    const valueParsed = parseTime(value);
    return !valueParsed || !timeParsed || timeParsed <= valueParsed || error;
  },
  beforeTime: (value: string, time: string, error: string) => {
    const timeParsed = parseDate(time);
    const valueParsed = parseTime(value);
    return !valueParsed || !timeParsed || timeParsed >= valueParsed || error;
  },
  lessThanDuration: (start: string, end: string, duration: string, error?: string) => {
    const startParsed = parseDate(start);
    const endParsed = parseTime(end);
    const durationParsed = durationControlParseDuration(duration || "15 min");
    return (
      !end ||
      !start ||
      addMinutes(startParsed, durationParsed as number) <= (endParsed as Date) ||
      error ||
      `Not enough time (${duration})`
    );
  },
};

export type TimeControlRenderProps = MuiTextFieldProps & RhfControlledRenderProps & {};

export type TimeControlProps = Omit<MuiTextFieldProps, "required"> &
  Omit<RhfControlledProps<FieldValues, TimeControlRenderProps>, "render"> & {
    format?: TimeFormat;
    chronoOptions?: ChronoParsingOption;
    required?: string | boolean;
    shiftMeridiemLimit?: Date;
  };

export const TimeControl: IRhfControl<TimeControlProps> = ({
  rules,
  format = "TIME_DISPLAY_FORMAT",
  chronoOptions,
  required,
  shiftMeridiemLimit,
  ...rest
}) => {
  const { format: formatFn } = useDateTimeFormatUtils();

  const merged: TimeControlProps["rules"] = useMemo(
    () =>
      deepmerge({}, rules, {
        required,
        validate: {
          // unambiguous: (v: string) => !!v && /^\d(\:(\d){0,2})?$/.test(v.trim()) || "am or pm",
          parseable: (v: string) => !v || !!parseTime(v, chronoOptions) || "invalid time",
        },
      }),
    [chronoOptions, rules, required]
  );

  const render = useCallback(
    ({ field, fieldState, formState, ...rest }: TimeControlRenderProps) => {
      const { name, value, ref: inputRef, onChange, onBlur } = field;

      const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e);
        rest.onChange?.(e);
      };

      const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        const parsed = parseTime(e.target.value, chronoOptions);

        // input is a valid time format
        if (!!parsed) {
          if (!!shiftMeridiemLimit && isAfter(shiftMeridiemLimit, parsed)) {
            const timeShift = addTime(formatFn, parsed, { hour: 12 });
            onChange(formatFn(timeShift, format));
          } else {
            onChange(formatFn(parsed, format));
          }
        }

        onBlur();
        rest.onBlur?.(e);
      };

      return (
        <MuiTextField
          {...rest}
          inputRef={inputRef}
          {...{ name, value, onChange: handleChange, onBlur: handleBlur }}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      );
    },
    [chronoOptions, format, shiftMeridiemLimit, formatFn]
  );

  return <RhfControlled {...rest} rules={merged} render={render} />;
};

TimeControl.isControl = true;
TimeControl.isController = true;
